import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03c3bc6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dt-bet-group-root" }
const _hoisted_2 = {
  key: 0,
  class: "error-container"
}
const _hoisted_3 = { class: "error" }
const _hoisted_4 = {
  key: 0,
  class: "success-container"
}
const _hoisted_5 = {
  key: 0,
  class: "success"
}
const _hoisted_6 = {
  key: 1,
  class: "mobile-success"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bet_view = _resolveComponent("bet-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bet_view, {
      class: _normalizeClass(["b1", { disabled: !_ctx.allowBet }]),
      betType: 3,
      confimerdBet: _ctx.finalRedBet,
      tempBet: _ctx.redTemp,
      winner: _ctx.showResult && _ctx.gameResult?.whoWin === 1,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectBetView(3)))
    }, null, 8, ["class", "confimerdBet", "tempBet", "winner"]),
    _createVNode(_component_bet_view, {
      class: _normalizeClass(["b2", { disabled: !_ctx.allowBet }]),
      betType: 5,
      confimerdBet: _ctx.finalGreenBet,
      tempBet: _ctx.greenTemp,
      winner: _ctx.showResult && _ctx.gameResult?.whoWin === 2,
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectBetView(5)))
    }, null, 8, ["confimerdBet", "class", "tempBet", "winner"]),
    _createVNode(_component_bet_view, {
      class: _normalizeClass(["b3", { disabled: !_ctx.allowBet }]),
      betType: 4,
      confimerdBet: _ctx.finalBlueBet,
      tempBet: _ctx.blueTemp,
      winner: _ctx.showResult && _ctx.gameResult?.whoWin === 3,
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectBetView(4)))
    }, null, 8, ["confimerdBet", "class", "tempBet", "winner"]),
    _createVNode(_Transition, { name: "fadein-out" }, {
      default: _withCtx(() => [
        (_ctx.toastCount > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.toastMessage), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fadein-out" }, {
      default: _withCtx(() => [
        (_ctx.successCount > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (!_ctx.isMobile)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, "下注成功"))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}