
import { BetType } from "@/types/types";
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "bet-view",
  props: {
    betType: {
      required: true,
      type: Number as PropType<number>,
    },
    confimerdBet: {
      required: true,
      type: Number as PropType<number>,
    },
    tempBet: {
      required: true,
      type: Number as PropType<number>,
    },
    winner: {
      required: true,
      type: Boolean as PropType<boolean>,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const betLabel = computed(() => {
      switch (props.betType) {
        case BetType.BANKER:
          return t("banker");
        case BetType.TIGER:
          return t("dragon");
        case BetType.PLAYER:
          return t("player");
        case BetType.DRAGON:
          return t("tiger");
        case BetType.TIE:
        case BetType.DT_TIE:
          return t("tie");
        case BetType.BANKER_PAIR:
          return t("bankerpair");
        case BetType.PLAYER_PAIR:
          return t("playerpair");
      }
      return "";
    });

    const betColor = computed(() => {
      switch (props.betType) {
        case BetType.TIGER:
        case BetType.BANKER:
        case BetType.BANKER_PAIR:
          return "red";
        case BetType.PLAYER:
        case BetType.DRAGON:
        case BetType.PLAYER_PAIR:
          return "blue";
        case BetType.TIE:
        case BetType.DT_TIE:
          return "green";
      }
      return "";
    });

    return {
      betLabel,
      betColor,
    };
  },
});
