
import { useStore } from "@/store";
import { AppMutation } from "@/store/types";
import BussEventType from "@/types/BussEventType";
import {
  computed,
  defineComponent,
  Emitter,
  inject,
  onMounted,
  PropType,
  ref,
} from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    activeChip: {
      type: Number as PropType<number>,
    },
  },
  emits: ["clicked", "unselect"],
  setup(props, context) {
    const store = useStore();
    const { t } = useI18n();
    const emitter = inject("emitter") as Emitter;
    const isEditMode = ref<boolean>(false);
    const customChipEL = ref<HTMLElement>();
    const customChipValue = ref<string>("");
    let clickAndDbClickTimer: number | null = null;
    let clickAndDbClickCounter = 0;

    const handleClick = () => {
      clickAndDbClickCounter++;

      if (clickAndDbClickCounter === 1) {
        clickAndDbClickTimer = setTimeout(() => {
          clickAndDbClickCounter = 0;
          // do something on clicked

          if (customChip.value > 0) {
            context.emit("clicked", customChip.value);
          }
        }, 300);

        return;
      }

      if (typeof clickAndDbClickTimer === "number") {
        clearTimeout(clickAndDbClickTimer);
        clickAndDbClickTimer = null;
      }

      clickAndDbClickCounter = 0;

      // do something on double clicked
      if (customChip.value > 0) {
        customChipValue.value = String(customChip.value);
      } else {
        customChipValue.value = "0";
      }

      if (isMobile.value) {
        emitter.emit(BussEventType.SHOW_OR_HIDE_NUM_KEYBOARD, true);
      } else {
        isEditMode.value = true;

        setTimeout(() => {
          if (customChipEL.value !== undefined) {
            setFocusOnElement(customChipEL.value);
          }
        }, 100);
      }
    };

    const handleClickOutside = () => {
      if (!isMobile.value) {
        isEditMode.value = false;
        tryUpdateChipCustom();
      }
    };

    const setFocusOnElement = (_el: HTMLElement) => {
      _el.focus();
      document.execCommand("selectAll", false, undefined);
    };

    const releaseFocusOnElement = (_el: HTMLElement) => {
      _el.blur();
    };

    const formatCash = (n: number): string => {
      if (n < 1e3) return n.toString();
      if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
      if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
      if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
      if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";

      return n.toString();
    };

    const handleInputEvt = (_event: KeyboardEvent) => {
      const { key } = _event;
      const regex = new RegExp(/[^0-9]/, "g");
      if (key.toLowerCase() !== "delete" && key.toLowerCase() !== "backspace") {
        if (key.match(regex)) {
          _event.preventDefault();
        }
      }
      if (key.toLowerCase() === "enter" && customChipEL.value !== undefined) {
        releaseFocusOnElement(customChipEL.value);
        isEditMode.value = false;
        tryUpdateChipCustom();
      }
    };

    const tryUpdateChipCustom = () => {
      if (!customChipEL.value) return;
      const newChipCustomValue = parseInt(customChipEL.value.innerText);

      if (!isNaN(newChipCustomValue)) {
        store.commit(AppMutation.SET_CUSTOM_CHIP, newChipCustomValue);
        if (props.activeChip !== 22) {
          if (newChipCustomValue !== customChip.value) {
            context.emit("clicked");
          }
        }
      } else {
        store.commit(AppMutation.SET_CUSTOM_CHIP, 0);
        customChipValue.value = "自定义";

        if (props.activeChip === 22) {
          context.emit("unselect");
          console.log("op");
        }
      }
    };

    const customChip = computed((): number => {
      const chipValue = store.getters["customChip"] as number;

      return chipValue;
    });

    const showChipToolTip = computed(
      (): boolean => store.getters["showChipToolTip"]
    );

    const isMobile = computed((): boolean => store.getters["isMobile"]);

    onMounted(() => {
      if (showChipToolTip.value) {
        setTimeout(() => {
          store.commit(AppMutation.HIDE_CUSTOM_CHIP_TOOL_TIP);
        }, 4000);
      }
    });
    return {
      isEditMode,
      customChipEL,
      customChip,
      customChipValue,
      showChipToolTip,
      isMobile,
      t,
      handleInputEvt,
      handleClick,
      formatCash,
      handleClickOutside,
    };
  },
});
