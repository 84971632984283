import { GAMETYPE } from "@/components/roadmap/datamodel/types";
import { useStore } from "@/store";
import { GameResult } from "@/types/GameResult";
import { BET, BetType } from "@/types/types";
import audioPlayer, { SOUNDKEY } from "@/utils/sounds";
import {
  ref,
  inject,
  Emitter,
  SetupContext,
  computed,
  LimitInfo,
  CurrentDesk,
} from "vue";

export function useBetGroup(
  props: {
    allowBet: boolean;
    redConfirmedBet: number;
    blueConfirmedBet: number;
    greenConfirmedBet: number;
    bluePairConfirmedBet: number;
    redPairConfirmedBet: number;
    gameType: number;
  },
  context: SetupContext
) {
  const emitter = inject("emitter") as Emitter;
  const store = useStore();
  const selectedType = ref<number>(-1);
  const redTemp = ref<number>(0);
  const blueTemp = ref<number>(0);
  const greenTemp = ref<number>(0);
  const redPairTemp = ref<number>(0);
  const bluePairTemp = ref<number>(0);

  const confirmedRed = ref<number>(0);
  const confirmedBlue = ref<number>(0);
  const confirmedGreen = ref<number>(0);
  const confirmedRedPair = ref<number>(0);
  const confirmedBluePair = ref<number>(0);

  const toastCount = ref<number>(0);
  const toastMessage = ref<string>("");
  const successCount = ref<number>(0);

  let toastInterval: boolean | number = false;
  let successInterval: boolean | number = false;
  let showResultTimeout: boolean | number = false;

  const showResult = ref<boolean>(false);
  const gameResult = ref<GameResult | undefined>(undefined);

  const resetBet = () => {
    selectedType.value = -1;

    redTemp.value = 0;
    blueTemp.value = 0;
    greenTemp.value = 0;
    redPairTemp.value = 0;
    bluePairTemp.value = 0;

    confirmedRedPair.value = 0;
    confirmedBluePair.value = 0;
    confirmedRed.value = 0;
    confirmedBlue.value = 0;
    confirmedGreen.value = 0;

    trigerIfHasTempBet();
  };

  const resetExclude = () => {
    selectedType.value = -1;
    redTemp.value = 0;
    blueTemp.value = 0;
    greenTemp.value = 0;
    redPairTemp.value = 0;
    bluePairTemp.value = 0;
    trigerIfHasTempBet();
  };

  const trigerIfHasTempBet = () => {
    let hasTempBet = false;
    if (props.gameType === GAMETYPE.baccarat) {
      hasTempBet =
        redTemp.value +
          redPairTemp.value +
          blueTemp.value +
          bluePairTemp.value +
          greenTemp.value >
        0;
    } else {
      hasTempBet = redTemp.value + blueTemp.value + greenTemp.value > 0;
    }

    context.emit("on-update-temp-bet", hasTempBet);
  };

  const handleOnPlaceBet = (_p: { amount: number; betType: number }) => {
    if (!props.allowBet) return;

    const messageCode = isBetOk(_p.betType, _p.amount);
    switch (messageCode) {
      case BET.CODE.NO_BET_AREA_SELECTED:
        showErrorMessage(BET.MESSAGE.NO_BET_AREA_SELECTED);
        break;
      case BET.CODE.INSUFICIENT_BALANCE:
        showErrorMessage(BET.MESSAGE.INSUFICIENT_BALANCE);
        break;
      case BET.CODE.ABOVE_MAXIMUM:
        showErrorMessage(BET.MESSAGE.ABOVE_MAXIMUM);
        break;
      case BET.CODE.OK:
        audioPlayer.Play(SOUNDKEY.BET_PLACE);
        break;
      default:
        break;
    }

    trigerIfHasTempBet();
  };

  const isBetOk = (_betType: number, amount: number): number => {
    // check if there is a selected bet area
    if (_betType === -1) {
      return BET.CODE.NO_BET_AREA_SELECTED;
    }

    // get the total temp bet
    let totalTempbet = 0;
    if (props.gameType === GAMETYPE.baccarat) {
      totalTempbet =
        redTemp.value +
        blueTemp.value +
        greenTemp.value +
        amount +
        redPairTemp.value +
        bluePairTemp.value;
    } else {
      totalTempbet = redTemp.value + blueTemp.value + greenTemp.value + amount;
    }

    // check if there  is a temp bet
    if (totalTempbet === 0) {
      showErrorMessage(BET.MESSAGE.THERE_IS_NO_BET);
      return BET.CODE.THERE_IS_NO_BET;
    }

    // check if user coin, if less that the total temp bet
    if (totalTempbet > coin.value) {
      fillInBet(_betType);
      return BET.CODE.INSUFICIENT_BALANCE;
    } else {
      let tempBet = 0;
      let max = limit.value.maxBet ?? 0;
      let confirmed = 0;
      if (_betType === BetType.DRAGON || _betType === BetType.BANKER) {
        tempBet = redTemp.value;
        confirmed = props.redConfirmedBet;
      } else if (_betType === BetType.TIGER || _betType === BetType.PLAYER) {
        tempBet = blueTemp.value;
        confirmed = props.blueConfirmedBet;
      } else if (_betType === BetType.TIE || _betType === BetType.DT_TIE) {
        tempBet = greenTemp.value;
        max = limit.value.tieMaxBet ?? 0;
        confirmed = props.greenConfirmedBet;
      } else if (_betType === BetType.BANKER_PAIR) {
        tempBet = redPairTemp.value;
        confirmed = props.redPairConfirmedBet;
        max = limit.value.pairMaxBet || 0;
      } else if (_betType === BetType.PLAYER_PAIR) {
        tempBet = bluePairTemp.value;
        confirmed = props.bluePairConfirmedBet;
        max = limit.value.pairMaxBet || 0;
      }

      if (isAboveMaximum(_betType, tempBet, confirmed, amount, max)) {
        return BET.CODE.ABOVE_MAXIMUM;
      }
    }

    return BET.CODE.OK;
  };

  const isAboveMaximum = (
    betType: number,
    tempBet: number,
    confirmed: number,
    amount: number,
    max: number
  ): boolean => {
    const totalTemp = tempBet + amount + confirmed;
    let amt = amount;
    let result = false;

    if (betType === BetType.TIE || betType === BetType.DT_TIE) {
      if (max < totalTemp) {
        amt = max - tempBet;
        result = true;
      }
    } else if (
      betType === BetType.PLAYER_PAIR ||
      betType === BetType.BANKER_PAIR
    ) {
      if (max < totalTemp) {
        amt = max - tempBet;
        result = true;
      }
    } else {
      let allRedTotal = redTemp.value + props.redConfirmedBet;
      let allBlueTotal = blueTemp.value + props.blueConfirmedBet;
      let difference = 0;

      if (betType === BetType.DRAGON || betType === BetType.BANKER) {
        // red
        allRedTotal += amount;
      } else if (betType === BetType.TIGER || betType === BetType.PLAYER) {
        // blue
        allBlueTotal += amount;
      }
      if (allRedTotal > allBlueTotal) {
        difference = allRedTotal - allBlueTotal;
      } else {
        difference = allBlueTotal - allRedTotal;
      }

      if (difference > max) {
        const fillAmount = max - difference;
        if (fillAmount < 0) {
          applyTempBet(betType, amount - Math.abs(fillAmount));
        }
        return true;
      }
    }
    applyTempBet(betType, amt);
    return result;
  };

  const showErrorMessage = (message: string) => {
    disposeSuccessMessage();
    toastCount.value = 9;
    toastMessage.value = message;
    if (typeof toastInterval !== "number") {
      toastInterval = setInterval(() => {
        if (toastCount.value > 0) {
          toastCount.value -= 1;
        } else if (toastCount.value <= 0 && typeof toastInterval === "number") {
          disposeToastMessage();
        }
      }, 200);
    }
  };

  const disposeSuccessMessage = () => {
    if (typeof successInterval === "number") {
      clearInterval(successInterval);
      successInterval = false;
      successCount.value = 0;
    }
  };
  const disposeShowResultTimeout = () => {
    if (typeof showResultTimeout === "number") {
      clearTimeout(showResultTimeout);
      showResultTimeout = false;
      gameResult.value = undefined;
    }
  };

  const disposeToastMessage = () => {
    if (typeof toastInterval === "number") {
      clearInterval(toastInterval);
      toastInterval = false;
      toastCount.value = 0;
      toastMessage.value = "";
    }
  };

  const fillInBet = (betType: number) => {
    // fill in bet code
    const totalTempbet = redTemp.value + blueTemp.value + greenTemp.value;
    const tempBet = coin.value - totalTempbet;
    applyTempBet(betType, tempBet);
  };

  const applyTempBet = (_betType: number, amount: number) => {
    if (_betType === BetType.DRAGON || _betType === BetType.BANKER) {
      redTemp.value += amount;
    } else if (_betType === BetType.TIGER || _betType === BetType.PLAYER) {
      blueTemp.value += amount;
    } else if (_betType === BetType.TIE || _betType === BetType.DT_TIE) {
      greenTemp.value += amount;
    } else if (_betType === BetType.PLAYER_PAIR) {
      bluePairTemp.value += amount;
    } else if (_betType === BetType.BANKER_PAIR) {
      redPairTemp.value += amount;
    }
  };

  const showSuccessMessage = () => {
    disposeToastMessage();
    successCount.value = 9;
    if (typeof successInterval !== "number") {
      successInterval = setInterval(() => {
        if (successCount.value > 0) {
          successCount.value -= 1;
        } else if (
          successCount.value <= 0 &&
          typeof successInterval === "number"
        ) {
          disposeSuccessMessage();
        }
      }, 200);
    }
  };

  const coin = computed(() => store.getters["coin"]);
  const limit = computed((): LimitInfo => store.getters["limitInfo"]);
  const desk = computed((): CurrentDesk => store.getters["currentDesk"]);
  const user = computed(() => store.getters["user"]);
  const token = computed(() => store.getters["token"]);
  const isMobile = computed((): boolean => store.getters["isMobile"]);

  const finalRedBet = computed(() => {
    let _amt = 0;
    if (props.redConfirmedBet >= confirmedRed.value) {
      _amt = props.redConfirmedBet;
    } else {
      _amt = confirmedRed.value;
    }

    return _amt;
  });

  const finalBlueBet = computed(() => {
    let _amt = 0;
    if (props.blueConfirmedBet >= confirmedBlue.value) {
      _amt = props.blueConfirmedBet;
    } else {
      _amt = confirmedBlue.value;
    }

    return _amt;
  });

  const finalGreenBet = computed(() => {
    let _amt = 0;
    if (props.greenConfirmedBet >= confirmedGreen.value) {
      _amt = props.greenConfirmedBet;
    } else {
      _amt = confirmedGreen.value;
    }

    return _amt;
  });

  const finalBluePairBet = computed(() => {
    let _amt = 0;
    if (props.bluePairConfirmedBet >= confirmedBluePair.value) {
      _amt = props.bluePairConfirmedBet;
    } else {
      _amt = confirmedBluePair.value;
    }

    return _amt;
  });

  const finalRedPairBet = computed(() => {
    let _amt = 0;
    if (props.redPairConfirmedBet >= confirmedRedPair.value) {
      _amt = props.redPairConfirmedBet;
    } else {
      _amt = confirmedRedPair.value;
    }

    return _amt;
  });

  return {
    redTemp,
    blueTemp,
    greenTemp,
    gameResult,
    redPairTemp,
    bluePairTemp,
    confirmedRed,
    confirmedBlue,
    confirmedGreen,
    confirmedRedPair,
    confirmedBluePair,
    finalRedPairBet,
    finalBluePairBet,
    finalBlueBet,
    finalGreenBet,
    finalRedBet,
    showResult,
    toastCount,
    toastMessage,
    successCount,
    selectedType,
    emitter,
    coin,
    limit,
    desk,
    user,
    token,
    isMobile,
    resetBet,
    resetExclude,
    handleOnPlaceBet,
    disposeShowResultTimeout,
    disposeToastMessage,
    disposeSuccessMessage,
    showErrorMessage,
    showSuccessMessage,
  };
}
