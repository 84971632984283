import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d15fa5c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "value"
}
const _hoisted_2 = ["contenteditable"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "custom-chip-root",
    onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)), ["stop"]))
  }, [
    (!_ctx.isEditMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.customChip === 0 ? _ctx.t("custom") : _ctx.formatCash(_ctx.customChip)), 1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", {
      class: "custom",
      ref: "customChipEL",
      contenteditable: !_ctx.isMobile,
      onKeydown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInputEvt && _ctx.handleInputEvt(...args)))
    }, _toDisplayString(_ctx.customChipValue), 41, _hoisted_2), [
      [_vShow, _ctx.isEditMode]
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["tool-tip", _ctx.showChipToolTip ? 'scalingin' : 'scalinginout'])
    }, " 双击定义筹码 ", 2)
  ], 512)), [
    [_directive_click_outside, _ctx.handleClickOutside]
  ])
}