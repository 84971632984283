
import { defineComponent, PropType, onBeforeMount, onBeforeUnmount } from "vue";
import BetView from "@/components/betting-view/bet-view.vue";
import { useBetGroup } from "@/composables/useBetGroup";
import BussEventType from "@/types/BussEventType";
import { GameResult } from "@/types/GameResult";
import audioPlayer, { SOUNDKEY } from "@/utils/sounds";
import { BET, RESULT } from "@/types/types";
import { BaccaratBetParams } from "@/types/api-params";
import { store } from "@/store";
import { AppActions, AppMutation } from "@/store/types";
import router from "@/router";

export default defineComponent({
  name: "b-bet-group",
  props: {
    allowBet: {
      default: false,
      type: Boolean as PropType<boolean>,
    },
    redConfirmedBet: {
      required: true,
      type: Number as PropType<number>,
    },
    blueConfirmedBet: {
      required: true,
      type: Number as PropType<number>,
    },
    greenConfirmedBet: {
      required: true,
      type: Number as PropType<number>,
    },
    redPairConfirmedBet: {
      required: true,
      type: Number as PropType<number>,
    },
    bluePairConfirmedBet: {
      required: true,
      type: Number as PropType<number>,
    },
    gameType: {
      default: 1,
      required: false,
      type: Number as PropType<number>,
    },
    chipAmount: {
      type: Number as PropType<number>,
    },
  },
  components: {
    "bet-view": BetView,
  },
  setup(props, context) {
    const {
      selectedType,
      redTemp,
      blueTemp,
      greenTemp,
      bluePairTemp,
      redPairTemp,
      confirmedRed,
      confirmedBlue,
      confirmedGreen,
      confirmedRedPair,
      confirmedBluePair,
      finalRedPairBet,
      finalBluePairBet,
      finalBlueBet,
      finalGreenBet,
      finalRedBet,
      emitter,
      resetBet,
      resetExclude,
      handleOnPlaceBet,
      gameResult,
      showResult,
      toastMessage,
      toastCount,
      successCount,
      coin,
      limit,
      desk,
      user,
      isMobile,
      token,
      disposeShowResultTimeout,
      disposeToastMessage,
      disposeSuccessMessage,
      showErrorMessage,
      showSuccessMessage,
    } = useBetGroup(props, context);

    let showResultTimeout: boolean | number = false;

    const confirmBet = () => {
      if (!props.allowBet) return;

      const totalTempBet =
        redTemp.value +
        greenTemp.value +
        redPairTemp.value +
        blueTemp.value +
        bluePairTemp.value;
      if (totalTempBet === 0) {
        showErrorMessage(BET.MESSAGE.THERE_IS_NO_BET);
        return;
      }

      // check user point if less than the total temp bet
      if (totalTempBet > coin.value) {
        showErrorMessage(BET.MESSAGE.INSUFICIENT_BALANCE);
        resetBet();
      } else {
        // check limit

        // red bet
        if (redTemp.value > 0) {
          if (
            !isValidBet(
              redTemp.value,
              props.redConfirmedBet,
              limit.value.minBet || 0
            )
          ) {
            showErrorMessage(BET.MESSAGE.BELOW_MINIMUM);
            return;
          }
        }

        // blue bet
        if (blueTemp.value > 0) {
          if (
            !isValidBet(
              blueTemp.value,
              props.blueConfirmedBet,
              limit.value.minBet || 0
            )
          ) {
            showErrorMessage(BET.MESSAGE.BELOW_MINIMUM);
            return;
          }
        }

        // green
        if (greenTemp.value > 0) {
          if (
            !isValidBet(
              greenTemp.value,
              props.greenConfirmedBet,
              limit.value.tieMinBet || 0
            )
          ) {
            showErrorMessage(BET.MESSAGE.BELOW_MINIMUM);
            return;
          }

          if (
            !isNotGreaterThanMaxBet(
              greenTemp.value,
              props.greenConfirmedBet,
              limit.value.tieMaxBet || 0
            )
          ) {
            showErrorMessage(BET.MESSAGE.ABOVE_MAXIMUM);
            return;
          }
        }

        // red pair
        if (redPairTemp.value > 0) {
          if (
            !isValidBet(
              redPairTemp.value,
              props.redPairConfirmedBet,
              limit.value.pairMinBet || 0
            )
          ) {
            showErrorMessage(BET.MESSAGE.BELOW_MINIMUM);
            return;
          }

          if (
            !isNotGreaterThanMaxBet(
              redPairTemp.value,
              props.redPairConfirmedBet,
              limit.value.pairMaxBet || 0
            )
          ) {
            showErrorMessage(BET.MESSAGE.ABOVE_MAXIMUM);
            return;
          }
        }

        // blue pair
        if (bluePairTemp.value > 0) {
          if (
            !isValidBet(
              bluePairTemp.value,
              props.bluePairConfirmedBet,
              limit.value.pairMinBet || 0
            )
          ) {
            showErrorMessage(BET.MESSAGE.BELOW_MINIMUM);
            return;
          }

          if (
            !isNotGreaterThanMaxBet(
              bluePairTemp.value,
              props.bluePairConfirmedBet,
              limit.value.pairMaxBet || 0
            )
          ) {
            showErrorMessage(BET.MESSAGE.ABOVE_MAXIMUM);
            return;
          }
        }

        // check min and max of player and banker
        const allRedTotal = redTemp.value + props.redConfirmedBet;
        const allBlueTotal = blueTemp.value + props.blueConfirmedBet;
        let diffrence = 0;
        if (allRedTotal > allBlueTotal) {
          diffrence = allRedTotal - allBlueTotal;
        } else {
          diffrence = allBlueTotal - allRedTotal;
        }

        if (diffrence > (limit.value.maxBet || 0)) {
          showErrorMessage(BET.MESSAGE.ABOVE_MAXIMUM);
          return;
        }

        const model = new BaccaratBetParams(
          desk.value.desk,
          desk.value.xian,
          user.value,
          token.value
        );
        if (redTemp.value > 0) model.y_z = redTemp.value;
        if (blueTemp.value > 0) model.y_x = blueTemp.value;
        if (redPairTemp.value > 0) model.y_zd = redPairTemp.value;
        if (bluePairTemp.value > 0) model.y_xd = bluePairTemp.value;
        if (greenTemp.value > 0) model.y_h = greenTemp.value;

        store
          .dispatch(AppActions.REQUEST_TO_API, model)
          .then((response: string) => {
            if (response.includes("err=2")) {
              store.commit(AppMutation.CLEAR_ALL);
              emitter.emit(BussEventType.TOAST_MESSAGE, "会话已过期");
              router.push({ name: "select-server-line" });
            } else {
              const arrInfo = response.split("#");
              if (response.includes("xzok")) {
                store.commit(AppMutation.SET_COIN, parseInt(arrInfo[1]));

                confirmedRed.value = props.redConfirmedBet + redTemp.value;
                confirmedBlue.value = props.blueConfirmedBet + blueTemp.value;
                confirmedGreen.value =
                  props.greenConfirmedBet + greenTemp.value;
                confirmedRedPair.value =
                  props.redPairConfirmedBet + redPairTemp.value;
                confirmedBluePair.value =
                  props.bluePairConfirmedBet + bluePairTemp.value;
                audioPlayer.Play(SOUNDKEY.BET_SUCCESS);
                context.emit("bet-success", parseInt(arrInfo[2]));
                showSuccessMessage();
                resetExclude();
              } else {
                showErrorMessage(arrInfo[1]);
                resetBet();
              }
            }
          })
          .catch((err) => {
            showErrorMessage(err);
            resetBet();
          });
      }
    };

    const isNotGreaterThanMaxBet = (
      bet: number,
      confirmedBet: number,
      max: number
    ): boolean => {
      return bet + confirmedBet <= max;
    };

    const isValidBet = (
      bet: number,
      confirmedBet: number,
      min: number
    ): boolean => {
      return bet + confirmedBet >= min;
    };

    const handleShowGameResult = (_gameResult: GameResult) => {
      if (_gameResult) {
        gameResult.value = _gameResult;
        const { whoWin, bluePair, redpair } = gameResult.value;

        if (whoWin === RESULT.RED) {
          if (bluePair && redpair) {
            audioPlayer.Play(SOUNDKEY.BANKER_WIN_BOTH_PAIR);
          } else if (bluePair && !redpair) {
            audioPlayer.Play(SOUNDKEY.BANKER_WIN_PLAYER_PAIR);
          } else if (!bluePair && redpair) {
            audioPlayer.Play(SOUNDKEY.BANKER_WIN_BANKER_PAIR);
          } else {
            audioPlayer.Play(SOUNDKEY.BANKER_WIN);
          }
        } else if (whoWin === RESULT.BLUE) {
          if (bluePair && redpair) {
            audioPlayer.Play(SOUNDKEY.PLAYER_WIN_BOTH_PAIR);
          } else if (bluePair && !redpair) {
            audioPlayer.Play(SOUNDKEY.PLAYER_WIN_PLAYER_PAIR);
          } else if (!bluePair && redpair) {
            audioPlayer.Play(SOUNDKEY.PLAYER_WIN_BANKER_PAIR);
          } else {
            audioPlayer.Play(SOUNDKEY.PLAYER_WIN);
          }
        } else if (whoWin === RESULT.GREEN) {
          // play sound tie
          if (bluePair && redpair) {
            audioPlayer.Play(SOUNDKEY.TIE_BOTH_PAIR);
          } else if (bluePair && !redpair) {
            audioPlayer.Play(SOUNDKEY.TIE_PLAYER_PAIR);
          } else if (!bluePair && redpair) {
            audioPlayer.Play(SOUNDKEY.TIE_BANKER_PAIR);
          } else {
            audioPlayer.Play(SOUNDKEY.TIE);
          }
        }

        showResult.value = true;

        showResultTimeout = setTimeout(() => {
          showResult.value = false;
          gameResult.value = undefined;
        }, 6000);
      }
    };

    const selectBetView = (_betType: number) => {
      if (!props.allowBet) return;
      // if (selectedType.value === _betType) {
      //   selectedType.value = -1;
      //   return;
      // }

      // selectedType.value = _betType;

      // if (!props.allowBet) return;
      // if (selectedType.value === betType) {
      //   selectedType.value = -1;
      //   return;
      // }

      // selectedType.value = betType;

      //

      // console.log(props.chipAmount);

      emitter.emit(BussEventType.PLACE_BET, {
        amount: props.chipAmount,
        betType: _betType,
      });
    };

    //#endregion Vue Hook
    onBeforeMount(() => {
      emitter.on(BussEventType.PLACE_BET, handleOnPlaceBet);
      emitter.on(BussEventType.RESET_BET, resetBet);
      emitter.on(BussEventType.CONFIRM_BET, confirmBet);
      emitter.on(BussEventType.SHOW_GAME_RESULT, handleShowGameResult);
    });

    onBeforeUnmount(() => {
      emitter.off(BussEventType.PLACE_BET, handleOnPlaceBet);
      emitter.off(BussEventType.RESET_BET, resetBet);
      emitter.off(BussEventType.CONFIRM_BET, confirmBet);
      emitter.off(BussEventType.SHOW_GAME_RESULT, handleShowGameResult);

      // remove all toastMessage
      disposeToastMessage();
      disposeSuccessMessage();
      disposeShowResultTimeout();
    });
    //#endregion

    return {
      showResult,
      selectedType,
      gameResult,
      toastMessage,
      toastCount,
      successCount,
      redTemp,
      blueTemp,
      greenTemp,
      bluePairTemp,
      redPairTemp,
      finalRedPairBet,
      finalBluePairBet,
      finalBlueBet,
      finalGreenBet,
      finalRedBet,
      isMobile,
      selectBetView,
      resetBet,
    };
  },
});
