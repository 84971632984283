
import {
  computed,
  CurrentDesk,
  defineComponent,
  Emitter,
  inject,
  LimitInfo,
  onBeforeMount,
  onBeforeUnmount,
  PropType,
  ref,
} from "vue";
import BetView from "@/components/betting-view/bet-view.vue";
import BussEventType from "@/types/BussEventType";
import { BET, BetType, RESULT } from "@/types/types";
import { useStore } from "@/store";
import { DragonTigerBetParams } from "@/types/api-params";
import { AppActions, AppMutation } from "@/store/types";
import router from "@/router";
import { GameResult } from "@/types/GameResult";
import audioPlayer, { SOUNDKEY } from "@/utils/sounds";

export default defineComponent({
  name: "dt-bet-group",
  components: {
    "bet-view": BetView,
  },
  props: {
    allowBet: {
      default: false,
      type: Boolean as PropType<boolean>,
    },
    redConfirmedBet: {
      required: true,
      type: Number as PropType<number>,
    },
    blueConfirmedBet: {
      required: true,
      type: Number as PropType<number>,
    },
    greenConfirmedBet: {
      required: true,
      type: Number as PropType<number>,
    },
    chipAmount: {
      type: Number as PropType<number>,
    },
  },
  setup(props, context) {
    const store = useStore();
    const emitter = inject("emitter") as Emitter;
    const selectedType = ref<number>(-1);

    const redTemp = ref<number>(0);
    const blueTemp = ref<number>(0);
    const greenTemp = ref<number>(0);

    const confirmedGreen = ref<number>(0);
    const confirmedRed = ref<number>(0);
    const confirmedblue = ref<number>(0);
    const showResult = ref<boolean>(false);
    const gameResult = ref<GameResult | undefined>(undefined);

    const toastCount = ref<number>(0);
    const successCount = ref<number>(0);
    const toastMessage = ref<string>("");
    let toastInterval: boolean | number = false;
    let successInterval: boolean | number = false;
    let showResultTimeout: boolean | number = false;

    const selectBetView = (betType: number) => {
      if (!props.allowBet) return;
      // if (!props.allowBet) return;
      // if (selectedType.value === betType) {
      //   selectedType.value = -1;
      //   return;
      // }
      // selectedType.value = betType;
      //  emitter.emit(BussEventType.PLACE_BET, amount);

      emitter.emit(BussEventType.PLACE_BET, {
        amount: props.chipAmount,
        betType: betType,
      });
    };

    const resetBet = () => {
      selectedType.value = -1;
      redTemp.value = 0;
      blueTemp.value = 0;
      greenTemp.value = 0;
      confirmedRed.value = 0;
      confirmedblue.value = 0;
      confirmedGreen.value = 0;

      trigerIfHasTempBet();
    };

    const resetExclude = () => {
      selectedType.value = -1;
      redTemp.value = 0;
      blueTemp.value = 0;
      greenTemp.value = 0;
      trigerIfHasTempBet();
    };

    const handleOnPlaceBet = (_p: { amount: number; betType: number }) => {
      console.log(_p);
      if (!props.allowBet) return;

      const messageCode = isBetOk(_p.betType, _p.amount);

      console.log(messageCode);

      switch (messageCode) {
        case BET.CODE.NO_BET_AREA_SELECTED:
          showErrorMessage(BET.MESSAGE.NO_BET_AREA_SELECTED);
          break;
        case BET.CODE.INSUFICIENT_BALANCE:
          showErrorMessage(BET.MESSAGE.INSUFICIENT_BALANCE);
          break;
        case BET.CODE.ABOVE_MAXIMUM:
          showErrorMessage(BET.MESSAGE.ABOVE_MAXIMUM);
          break;
        case BET.CODE.OK:
          audioPlayer.Play(SOUNDKEY.BET_PLACE);
          break;
        default:
          break;
      }

      trigerIfHasTempBet();
    };

    const trigerIfHasTempBet = () => {
      const hasTempBet = redTemp.value + blueTemp.value + greenTemp.value > 0;
      context.emit("on-update-temp-bet", hasTempBet);
    };

    const showErrorMessage = (message: string) => {
      disposeSuccessMessage();
      toastCount.value = 9;
      toastMessage.value = message;
      if (typeof toastInterval !== "number") {
        toastInterval = setInterval(() => {
          if (toastCount.value > 0) {
            toastCount.value -= 1;
          } else if (
            toastCount.value <= 0 &&
            typeof toastInterval === "number"
          ) {
            disposeToastMessage();
          }
        }, 200);
      }
    };

    const showSuccessMessage = () => {
      disposeToastMessage();
      successCount.value = 9;
      if (typeof successInterval !== "number") {
        successInterval = setInterval(() => {
          if (successCount.value > 0) {
            successCount.value -= 1;
          } else if (
            successCount.value <= 0 &&
            typeof successInterval === "number"
          ) {
            disposeSuccessMessage();
          }
        }, 200);
      }
    };

    const fillInBet = (betType: number) => {
      // fill in bet code
      const totalTempbet = redTemp.value + blueTemp.value + greenTemp.value;
      const tempBet = coin.value - totalTempbet;
      applyTempBet(betType, tempBet);
    };

    const applyTempBet = (betType: number, amount: number) => {
      if (betType === BetType.DRAGON) {
        blueTemp.value += amount;
      } else if (betType === BetType.TIGER) {
        redTemp.value += amount;
      } else if (betType === BetType.DT_TIE) {
        greenTemp.value += amount;
      }
    };

    const isAboveMaximum = (
      betType: number,
      tempBet: number,
      confirmed: number,
      amount: number,
      max: number
    ): boolean => {
      const totalTemp = tempBet + amount + confirmed;
      let amt = amount;
      let result = false;

      if (betType === BetType.DT_TIE) {
        if (max < totalTemp) {
          amt = max - tempBet;
          result = true;
        }
      } else {
        let allBlueTotal = redTemp.value + props.redConfirmedBet;
        let allRedTotal = blueTemp.value + props.blueConfirmedBet;
        let difference = 0;

        if (betType === BetType.DRAGON) {
          // red
          allRedTotal += amount;
        } else if (betType === BetType.TIGER) {
          // blue
          allBlueTotal += amount;
        }
        if (allRedTotal > allBlueTotal) {
          difference = allRedTotal - allBlueTotal;
        } else {
          difference = allBlueTotal - allRedTotal;
        }

        if (difference > max) {
          const fillAmount = max - difference;
          if (fillAmount < 0) {
            applyTempBet(betType, amount - Math.abs(fillAmount));
          }
          return true;
        }
      }
      applyTempBet(betType, amt);
      return result;
    };

    const isBetOk = (betType: number, amount: number): number => {
      // check if there is a bet area selected
      if (betType === -1) {
        return BET.CODE.NO_BET_AREA_SELECTED;
      }

      // get the total temp bet
      const totalTempbet =
        redTemp.value + blueTemp.value + greenTemp.value + amount;
      // check if there there is a temp bet
      if (totalTempbet === 0) {
        showErrorMessage(BET.MESSAGE.THERE_IS_NO_BET);
        return BET.CODE.THERE_IS_NO_BET;
      }

      // check user point if less than the total temp bet
      if (totalTempbet > coin.value) {
        fillInBet(betType);
        return BET.CODE.INSUFICIENT_BALANCE;
      } else {
        let tempBet = 0;
        let max = limit.value.maxBet ?? 0;
        let confirmed = 0;
        if (betType === BetType.DRAGON) {
          tempBet = redTemp.value;
          confirmed = props.redConfirmedBet;
        } else if (betType === BetType.TIGER) {
          tempBet = blueTemp.value;
          confirmed = props.blueConfirmedBet;
        } else {
          tempBet = greenTemp.value;
          max = limit.value.tieMaxBet ?? 0;
          confirmed = props.greenConfirmedBet;
        }

        if (isAboveMaximum(betType, tempBet, confirmed, amount, max)) {
          return BET.CODE.ABOVE_MAXIMUM;
        }
      }

      return BET.CODE.OK;
    };

    const confirmBet = () => {
      if (!props.allowBet) return;
      const totalTempBet = redTemp.value + blueTemp.value + greenTemp.value;

      if (totalTempBet === 0) {
        showErrorMessage(BET.MESSAGE.THERE_IS_NO_BET);
        return;
      }

      // check user point if less than the total temp bet
      if (totalTempBet > coin.value) {
        showErrorMessage(BET.MESSAGE.INSUFICIENT_BALANCE);
        resetBet();
      } else {
        // check the limit
        if (redTemp.value > 0) {
          if (redTemp.value > 0) {
            if (
              !isValidBet(
                redTemp.value,
                props.redConfirmedBet,
                limit.value.minBet || 0
              )
            ) {
              showErrorMessage(BET.MESSAGE.BELOW_MINIMUM);
              return;
            }
          }
        }

        if (greenTemp.value > 0) {
          if (
            !isValidBet(
              greenTemp.value,
              props.greenConfirmedBet,
              limit.value.tieMinBet || 0
            )
          ) {
            showErrorMessage(BET.MESSAGE.BELOW_MINIMUM);
            return;
          }

          if (
            !isNotGreaterThanMaxBet(
              greenTemp.value,
              props.greenConfirmedBet,
              limit.value.tieMaxBet || 0
            )
          ) {
            showErrorMessage(BET.MESSAGE.ABOVE_MAXIMUM);
            return;
          }
        }

        if (blueTemp.value > 0) {
          if (
            !isValidBet(
              blueTemp.value,
              props.blueConfirmedBet,
              limit.value.minBet || 0
            )
          ) {
            showErrorMessage(BET.MESSAGE.BELOW_MINIMUM);
            return;
          }
        }
      }
      // check min and max of player and banker
      const allRedTotal = redTemp.value + props.redConfirmedBet;
      const allBlueTotal = blueTemp.value + props.blueConfirmedBet;
      let diffrence = 0;
      if (allRedTotal > allBlueTotal) {
        diffrence = allRedTotal - allBlueTotal;
      } else {
        diffrence = allBlueTotal - allRedTotal;
      }

      if (diffrence > (limit.value.maxBet || 0)) {
        showErrorMessage(BET.MESSAGE.ABOVE_MAXIMUM);
        return;
      }

      const model = new DragonTigerBetParams(
        desk.value.desk,
        desk.value.xian,
        user.value,
        token.value
      );

      model.long = String(redTemp.value);
      model.hu = String(blueTemp.value);
      model.he = String(greenTemp.value);

      store.commit(AppMutation.SHOW_LOADING);
      store
        .dispatch(AppActions.PLACE_DT_BET, model)
        .then((response: string) => {
          store.commit(AppMutation.HIDE_LOADING);
          if (response.includes("err=2")) {
            store.commit(AppMutation.CLEAR_ALL);
            emitter.emit(BussEventType.TOAST_MESSAGE, "会话已过期");
            router.push({ name: "select-server-line" });
          } else {
            const arrInfo = response.split("#");
            if (response.includes("xzok")) {
              store.commit(AppMutation.SET_COIN, parseInt(arrInfo[1]));

              confirmedRed.value = props.redConfirmedBet + redTemp.value;
              confirmedblue.value = props.blueConfirmedBet + blueTemp.value;
              confirmedGreen.value = props.greenConfirmedBet + greenTemp.value;
              audioPlayer.Play(SOUNDKEY.BET_SUCCESS);
              context.emit("bet-success", parseInt(arrInfo[2]));
              showSuccessMessage();
              resetExclude();
            } else {
              showErrorMessage(arrInfo[1]);
              resetBet();
            }
          }
        })
        .catch((err) => {
          store.commit(AppMutation.HIDE_LOADING);
          showErrorMessage(err);
          resetBet();
        });
    };

    const isValidBet = (
      bet: number,
      confirmedBet: number,
      min: number
    ): boolean => {
      return bet + confirmedBet >= min;
    };

    const isNotGreaterThanMaxBet = (
      bet: number,
      confirmedBet: number,
      max: number
    ): boolean => {
      return bet + confirmedBet <= max;
    };

    const disposeToastMessage = () => {
      if (typeof toastInterval === "number") {
        clearInterval(toastInterval);
        toastInterval = false;
        toastCount.value = 0;
        toastMessage.value = "";
      }
    };

    const disposeSuccessMessage = () => {
      if (typeof successInterval === "number") {
        clearInterval(successInterval);
        successInterval = false;
        successCount.value = 0;
      }
    };
    const disposeShowResultTimeout = () => {
      if (typeof showResultTimeout === "number") {
        clearTimeout(showResultTimeout);
        showResultTimeout = false;
        gameResult.value = undefined;
      }
    };

    const handleShowGameResult = (_gameResult: GameResult) => {
      if (_gameResult) {
        gameResult.value = _gameResult;
        const { whoWin } = gameResult.value;

        if (whoWin === RESULT.RED) {
          // play sound dragon
          audioPlayer.Play(SOUNDKEY.DRAGON_WIN);
        } else if (whoWin === RESULT.BLUE) {
          // play sound tiger
          audioPlayer.Play(SOUNDKEY.TIGER_WIN);
        } else if (whoWin === RESULT.GREEN) {
          // play sound tie
          audioPlayer.Play(SOUNDKEY.TIE);
        }

        showResult.value = true;

        showResultTimeout = setTimeout(() => {
          showResult.value = false;
          gameResult.value = undefined;
        }, 6000);
      }
    };

    //#region Cmputed
    const limit = computed((): LimitInfo => store.getters["limitInfo"]);
    const coin = computed((): number => store.getters["coin"]);
    const desk = computed((): CurrentDesk => store.getters["currentDesk"]);
    const user = computed((): string => store.getters["user"]);
    const token = computed((): string => store.getters["token"]);
    const isMobile = computed((): boolean => store.getters["isMobile"]);
    const finalRedBet = computed(() => {
      let _amt = 0;
      if (props.redConfirmedBet >= confirmedRed.value) {
        _amt = props.redConfirmedBet;
      } else {
        _amt = confirmedRed.value;
      }

      return _amt;
    });

    const finalBlueBet = computed(() => {
      let _amt = 0;
      if (props.blueConfirmedBet >= confirmedblue.value) {
        _amt = props.blueConfirmedBet;
      } else {
        _amt = confirmedblue.value;
      }

      return _amt;
    });

    const finalGreenBet = computed(() => {
      let _amt = 0;
      if (props.greenConfirmedBet >= confirmedGreen.value) {
        _amt = props.greenConfirmedBet;
      } else {
        _amt = confirmedGreen.value;
      }

      return _amt;
    });
    //#endregion

    //#region VueHook
    onBeforeMount(() => {
      emitter.on(BussEventType.PLACE_BET, handleOnPlaceBet);
      emitter.on(BussEventType.RESET_BET, resetBet);
      emitter.on(BussEventType.CONFIRM_BET, confirmBet);
      emitter.on(BussEventType.SHOW_GAME_RESULT, handleShowGameResult);
    });
    onBeforeUnmount(() => {
      emitter.off(BussEventType.PLACE_BET, handleOnPlaceBet);
      emitter.off(BussEventType.RESET_BET, resetBet);
      emitter.off(BussEventType.CONFIRM_BET, confirmBet);
      emitter.off(BussEventType.SHOW_GAME_RESULT, handleShowGameResult);

      // remove all toastMessage
      disposeToastMessage();
      disposeSuccessMessage();
      disposeShowResultTimeout();
    });
    //#endregion

    return {
      redTemp,
      blueTemp,
      greenTemp,
      selectedType,
      toastCount,
      successCount,
      toastMessage,
      showResult,
      gameResult,
      finalRedBet,
      finalBlueBet,
      finalGreenBet,
      isMobile,
      selectBetView,
      resetBet,
    };
  },
});
