import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "prediction-btn-root" }
const _hoisted_2 = {
  key: 0,
  class: "label1"
}
const _hoisted_3 = {
  key: 1,
  class: "label2"
}
const _hoisted_4 = {
  key: 2,
  class: "pre-item"
}
const _hoisted_5 = {
  key: 3,
  class: "pre-item"
}
const _hoisted_6 = {
  key: 4,
  class: "pre-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasNoData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(`${_ctx.label}${_ctx.t("ask")}`), 1))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.label), 1)),
    (!_ctx.hasNoData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", {
            class: _normalizeClass(["icon-circlestroke", _ctx.bigeyeColor])
          }, null, 2)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.hasSmall)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("span", {
            class: _normalizeClass(["icon-circlefill", _ctx.smallColor])
          }, null, 2)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.hasCockroach)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("span", {
            class: _normalizeClass(["icon-vertical", _ctx.cocroachColor])
          }, null, 2)
        ]))
      : _createCommentVNode("", true)
  ]))
}