
import {
  computed,
  CurrentDesk,
  defineComponent,
  DropDownItem,
  Emitter,
  inject,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import DropDownCtrl from "@/components/drop-down.vue";
import { useStore } from "@/store";
import { AppActions, AppMutation } from "@/store/types";
import { getValueByKey, getValueOfKeyValue } from "@/utils";
import { MultipleDeskParams, NoticeAndLimitParams } from "@/types/api-params";
import { Desk } from "@/types/Desk";
import { GAME_TYPE } from "@/types/types";
import { MobileSwitchTableItem } from "./";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "switch-table",
  components: {
    "drop-down-ctrl": DropDownCtrl,

    "list-item": MobileSwitchTableItem,
  },
  setup(props, context) {
    // const emitter = inject("emitter") as Emitter;
    const store = useStore();
    const { t } = useI18n();
    const dropdownCtrlData = ref<DropDownItem[]>([
      { id: 4, value: t("baccarat"), other: "" },
      { id: 2, value: t("dragontiger"), other: "" },
      { id: 5, value: t("dealfirst"), other: "" },
    ]);
    const desks = ref<Desk[]>([]);
    const selectedId = ref<number>(-1);
    const listKey = ref<number>(new Date().getTime());

    const PULL_INTERVAL = 3000;
    let serviceInterval: number | boolean = false;

    const loadData = (forceReload = false) => {
      if (!forceReload) store.commit(AppMutation.SHOW_LOADING);

      const params = new MultipleDeskParams(
        tabIndex.value,
        token.value,
        user.value
      );

      store
        .dispatch(AppActions.GET_DESKS, params)
        .then((response: string) => {
          if (!forceReload) store.commit(AppMutation.HIDE_LOADING);
          const err = getValueByKey(response, "err");
          if (err) {
            stopService();
            context.emit("end-session");
          } else {
            desks.value = Desk.ParseList(response);
            if (response.includes("$UserPoint=")) {
              const _userPointValuePair =
                response.split("$")[desks.value.length];
              const _coin = parseInt(
                getValueOfKeyValue(_userPointValuePair) ?? "0"
              );
              store.commit(AppMutation.SET_COIN, _coin);
            }
            if (!forceReload) startService();
          }
        })
        .catch(() => {
          if (!forceReload) {
            store.commit(AppMutation.HIDE_LOADING);
            setTimeout(() => {
              loadData();
            }, PULL_INTERVAL);
          }
        });
    };

    const startService = () => {
      stopService();
      serviceInterval = setInterval(() => {
        loadData(true);
      }, PULL_INTERVAL);
    };

    const stopService = () => {
      if (typeof serviceInterval === "number") {
        clearInterval(serviceInterval);
        serviceInterval = false;
      }
    };
    const onSelectTableId = (_id: number) => {
      if (selectedId.value === _id) selectedId.value = -1;
      else selectedId.value = _id;
    };

    const changeGameOption = (option: number) => {
      if (option === tabIndex.value) return;

      selectedId.value = -1;

      stopService();

      let _currentDesk: CurrentDesk = {
        desk: -1,
        xian: -1,
        gameType: option,
      };
      if (currentDesk.value) {
        _currentDesk = currentDesk.value;
        _currentDesk.gameType = option;
      }

      store.commit(AppMutation.SET_CURRENT_DESK, _currentDesk);

      desks.value = [];
      listKey.value = new Date().getDate();

      store.commit(AppMutation.SHOW_LOADING);

      setTimeout(() => {
        loadData();
      }, 500);
    };

    const handleEnterDesk = (_desk: Desk, limitid: number) => {
      const model = new NoticeAndLimitParams(
        _desk.tableNo,
        limitid,
        lang.value
      );
      store.commit(AppMutation.SHOW_LOADING);
      store
        .dispatch(AppActions.NOTICE_AND_LIMIT_INFO, model)
        .then((response: string) => {
          store.commit(AppMutation.HIDE_LOADING);
          if (response === "err=2") {
            stopService();
            context.emit("end-session");
          } else {
            context.emit(
              "enter-table",
              _desk.tableNo,
              _desk.gameType,
              tabIndex.value,
              limitid
            );
            context.emit("close");
          }
        })
        .catch((err) => {
          store.commit(AppMutation.HIDE_LOADING);
        });
    };

    const onGOptionSelectionChange = (_value: DropDownItem) => {
      changeGameOption(_value.id);
    };

    const currentDesk = computed(
      (): CurrentDesk => store.getters["currentDesk"] as CurrentDesk
    );

    const token = computed(() => store.getters["token"]);
    const user = computed(() => store.getters["user"]);

    const tabIndex = computed(() => {
      if (!currentDesk.value) return 4;

      return currentDesk.value.gameType;
    });

    const lang = computed(() => store.getters["lang"]);

    //#region Vue Hooks
    onMounted(() => {
      loadData();
    });
    onBeforeUnmount(() => {
      stopService();
    });
    //#endregion

    return {
      dropdownCtrlData,
      desks,
      tabIndex,
      listKey,
      selectedId,
      handleEnterDesk,
      onSelectTableId,
      onGOptionSelectionChange,
    };
  },
});
