import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "b-bet-group-root" }
const _hoisted_2 = { class: "center-bet-group" }
const _hoisted_3 = { class: "center-bottom-bet-group" }
const _hoisted_4 = {
  key: 0,
  class: "error-container"
}
const _hoisted_5 = { class: "error" }
const _hoisted_6 = {
  key: 0,
  class: "success-container"
}
const _hoisted_7 = {
  key: 0,
  class: "success"
}
const _hoisted_8 = {
  key: 1,
  class: "mobile-success"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bet_view = _resolveComponent("bet-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bet_view, {
      class: _normalizeClass(["left", { disabled: !_ctx.allowBet }]),
      betType: 2,
      confimerdBet: _ctx.finalBlueBet,
      tempBet: _ctx.blueTemp,
      winner: _ctx.showResult && _ctx.gameResult?.whoWin === 3,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectBetView(2)))
    }, null, 8, ["class", "confimerdBet", "tempBet", "winner"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_bet_view, {
        class: _normalizeClass(["center-top b1", { disabled: !_ctx.allowBet }]),
        betType: 1,
        confimerdBet: _ctx.finalGreenBet,
        tempBet: _ctx.greenTemp,
        winner: _ctx.showResult && _ctx.gameResult?.whoWin === 2,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectBetView(1)))
      }, null, 8, ["class", "confimerdBet", "tempBet", "winner"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_bet_view, {
          class: _normalizeClass(["center-bottom b1", { disabled: !_ctx.allowBet }]),
          betType: 7,
          confimerdBet: _ctx.finalBluePairBet,
          tempBet: _ctx.bluePairTemp,
          winner: _ctx.showResult && _ctx.gameResult?.bluePair,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectBetView(7)))
        }, null, 8, ["class", "confimerdBet", "tempBet", "winner"]),
        _createVNode(_component_bet_view, {
          class: _normalizeClass(["center-bottom b1", { disabled: !_ctx.allowBet }]),
          betType: 6,
          confimerdBet: _ctx.finalRedPairBet,
          tempBet: _ctx.redPairTemp,
          winner: _ctx.showResult && _ctx.gameResult?.redpair,
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectBetView(6)))
        }, null, 8, ["class", "confimerdBet", "tempBet", "winner"])
      ])
    ]),
    _createVNode(_component_bet_view, {
      class: _normalizeClass(["right", { disabled: !_ctx.allowBet }]),
      betType: 0,
      confimerdBet: _ctx.finalRedBet,
      tempBet: _ctx.redTemp,
      winner: _ctx.showResult && _ctx.gameResult?.whoWin === 1,
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectBetView(0)))
    }, null, 8, ["class", "confimerdBet", "tempBet", "winner"]),
    _createVNode(_Transition, { name: "fadein-out" }, {
      default: _withCtx(() => [
        (_ctx.toastCount > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.toastMessage), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fadein-out" }, {
      default: _withCtx(() => [
        (_ctx.successCount > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (!_ctx.isMobile)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7))
                : (_openBlock(), _createElementBlock("div", _hoisted_8, "下注成功"))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}