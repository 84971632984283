import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69c28a6b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "switch-table-root" }
const _hoisted_2 = { class: "switch-table-content-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_drop_down_ctrl = _resolveComponent("drop-down-ctrl")!
  const _component_list_item = _resolveComponent("list-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_drop_down_ctrl, {
        class: "drp-1",
        listData: _ctx.dropdownCtrlData,
        selectedIndex: _ctx.tabIndex,
        trimList: true,
        onOnSelectedChange: _ctx.onGOptionSelectionChange
      }, null, 8, ["listData", "selectedIndex", "onOnSelectedChange"]),
      _createElementVNode("span", {
        class: "icon-close",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      })
    ]),
    (_openBlock(), _createElementBlock("div", {
      class: "switch-table-content-list",
      key: _ctx.listKey
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.desks, (desk, index) => {
        return (_openBlock(), _createBlock(_component_list_item, {
          key: index,
          itemData: desk,
          onEnterTable: _ctx.handleEnterDesk,
          gameLabel: _ctx.tabIndex,
          selectedId: _ctx.selectedId,
          onClick: ($event: any) => (_ctx.onSelectTableId(desk.tableNo))
        }, null, 8, ["itemData", "onEnterTable", "gameLabel", "selectedId", "onClick"]))
      }), 128))
    ]))
  ]))
}