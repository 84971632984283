
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { RoadMapData } from "./roadmap/datamodel/RoadMapData";
import { RoadMapSummaryData } from "./roadmap/datamodel/types";

export default defineComponent({
  name: "prediction-btn",
  props: {
    label: {
      required: true,
      type: String as PropType<string>,
    },
    mapdata: {
      required: true,
      type: Object as PropType<RoadMapSummaryData>,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const hasNoData = computed(() => {
      if (!props.mapdata.data) return true;

      const mapData = props.mapdata.data as RoadMapData;

      return mapData.bigeyes.length <= 0;
    });

    const hasSmall = computed(() => {
      if (!props.mapdata.data) return true;

      const mapData = props.mapdata.data as RoadMapData;

      return mapData.smalls.length <= 0;
    });

    const hasCockroach = computed(() => {
      if (!props.mapdata.data) return true;

      const mapData = props.mapdata.data as RoadMapData;

      return mapData.cockroachs.length <= 0;
    });

    const bigeyeColor = computed(() => {
      if (!props.mapdata.data) return "";
      const mapData = props.mapdata.data as RoadMapData;

      if (mapData.bigeyes.length <= 0) return "";

      return mapData.bigeyes[mapData.bigeyes.length - 1].type === 0
        ? "red"
        : "blue";
    });

    const smallColor = computed(() => {
      if (!props.mapdata.data) return "";
      const mapData = props.mapdata.data as RoadMapData;

      if (mapData.smalls.length <= 0) return "";

      return mapData.smalls[mapData.smalls.length - 1].type === 0
        ? "red"
        : "blue";
    });

    const cocroachColor = computed(() => {
      if (!props.mapdata.data) return "";
      const mapData = props.mapdata.data as RoadMapData;

      if (mapData.cockroachs.length <= 0) return "";

      return mapData.cockroachs[mapData.cockroachs.length - 1].type === 0
        ? "red"
        : "blue";
    });

    return {
      hasNoData,
      hasSmall,
      hasCockroach,
      bigeyeColor,
      smallColor,
      cocroachColor,
      t,
    };
  },
});
