import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bet-view-container" }
const _hoisted_2 = ["data-bet", "data-temp"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bet-view-root", { flashing: _ctx.winner }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["bet-type", _ctx.betColor]),
        "data-bet": _ctx.confimerdBet > 0 ? _ctx.confimerdBet : '',
        "data-temp": _ctx.tempBet > 0 ? _ctx.tempBet : ''
      }, _toDisplayString(_ctx.betLabel), 11, _hoisted_2)
    ])
  ], 2))
}